/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import Container from "./container"

const Layout = ({ children, pageTitle, description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <Fragment>
      <Head title={pageTitle} description={description} />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          variant: "layout.root",
        }}
      >
        <Header
          siteTitle={data.site.siteMetadata.title}
          description={description || data.site.siteMetadata.description}
        />
        <main
          id="main"
          sx={{
            width: "100%",
            flex: "1 1 auto",
            variant: "layout.main",
          }}
        >
          {children}
        </main>
        <Footer />
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export { Container }
export { default as Row } from "./row"
export { default as Col } from "./col"

export default Layout
