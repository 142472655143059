import React from "react"

const Menu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="22" height="3" x="1" y="1" fill="#1A1E25" rx="1.5"></rect>
    <rect width="22" height="3" x="1" y="10" fill="#1A1E25" rx="1.5"></rect>
    <rect width="22" height="3" x="1" y="20" fill="#1A1E25" rx="1.5"></rect>
  </svg>
)

export default Menu
