import { alpha } from "@theme-ui/color"

export default {
  button: {
    alignItems: "center",
    background: "none",
    border: "medium solid transparent",
    display: "flex",
    py: 2,
    px: 3,
    fontSize: 3,
    textTransform: "uppercase",
    "&:hover": {
      borderColor: "primary",
    },
    "&:focus": {
      boxShadow: t => `0 0 0 3px ${alpha("primary", 0.4)(t)}`,
      outline: "none",
    },
    "&>svg": {
      ml: 2,
    },
  },
  overlay: {
    bg: alpha("dark", 0.96),
    zIndex: 1060,
  },
  content: { background: "none", mt: [5, 6], width: "100vw" },
  contentInner: {
    display: ["block", "flex"],
    flexWrap: "wrap",
    "& > a": {
      fontSize: [4, 5],
      fontWeight: "bold",
      mb: 3,
      color: "background",
      textDecoration: "none",
      width: "50%",
      "&:focus": {
        color: "secondary",
        outline: "none",
      },
      "&:after": {
        bg: "secondary",
        content: "''",
        display: "block",
        height: 3,
        mb: 3,
        mt: 2,
        transition: "width 200ms ease",
        width: "20%",
      },
      "&:focus:after": {
        width: "45%",
      },
      "&:hover:after": {
        width: "60%",
      },
    },
  },
}
