/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="341"
    height="58"
    viewBox="0 0 341 58"
    fill="none"
    sx={{
      height: ["30px", "60px"],
      width: "auto",
    }}
  >
    <path
      fill="#1A1E25"
      d="M257.33 48.467c-.838-.884-1.607-1.125-2.388-1.125-2.343 0-3.893 1.791-3.893 4.053 0 2.423 1.55 4.214 3.893 4.214.907 0 1.791-.413 2.549-1.377l1.676 1.194c-1.021 1.412-2.572 2.02-4.237 2.02-3.49 0-5.994-2.376-5.994-5.913 0-3.64 2.504-6.017 5.994-6.017 1.539 0 2.86.494 3.985 1.803l-1.585 1.148zm11.38-2.952c3.548-.069 6.051 2.308 6.051 5.948 0 3.548-2.503 5.925-6.051 5.983-3.503 0-6.006-2.377-6.006-5.914 0-3.651 2.503-6.017 6.006-6.017zm.011 10.105c2.354 0 3.916-1.791 3.916-4.214 0-2.262-1.562-4.054-3.916-4.054-2.342 0-3.904 1.792-3.904 4.054.011 2.411 1.562 4.214 3.904 4.214zm10.76-9.818h2.021v9.52h4.822v1.837h-6.843V45.802zm11.126 0h2.021v9.52h4.823v1.837h-6.844V45.802zm11.127 0h7.522v1.826h-5.501v2.79h5.213v1.838h-5.213v3.066h5.788v1.837h-7.809V45.802zm22.69 10.518c-1.378.735-2.951 1.126-4.696 1.126-3.491 0-5.994-2.377-5.994-5.914 0-3.64 2.503-6.017 5.994-6.017 1.734 0 3.284.368 4.478 1.424l-1.493 1.504c-.723-.7-1.825-1.102-2.974-1.102-2.342 0-3.892 1.791-3.892 4.053 0 2.423 1.55 4.215 3.892 4.215 1.022 0 1.952-.196 2.664-.598v-2.675h-2.331V50.51h4.352v5.81zm5.191-10.518h7.521v1.826h-5.5v2.79h5.213v1.838h-5.213v3.066h5.787v1.837h-7.808V45.802z"
    ></path>
    <path fill="#760A30" d="M12.459.698H0v30.866h12.459V.698z"></path>
    <path fill="#AF1232" d="M31.302.698h-12.47v30.866h12.47V.698z"></path>
    <path
      fill="#F17C14"
      d="M50.1 11.078c0-7.601-1.78-10.3-11.782-10.3h-.631v30.728h12.401V11.078h.012z"
    ></path>
    <path
      fill="#1A1E25"
      d="M101.933 4.314H66.049v27.192h5.546V9.608h15.893v21.898h5.523V9.608h8.646c3.491-.011 5.294.517 6.075 1.32.827.747 1.183 2.217 1.171 4.87v15.718h5.523V15.625c-.011-3.227-.47-6.109-2.445-8.222-1.976-2.101-5.179-3.089-10.048-3.089zm40.753.001h-9.152c-5.914-.012-9.623 1.561-11.736 4.237-2.101 2.664-2.468 6.051-2.48 9.358.012 3.319.379 6.706 2.48 9.37 2.113 2.664 5.822 4.249 11.736 4.237h9.209c5.879 0 9.565-1.573 11.678-4.237 2.101-2.664 2.469-6.051 2.48-9.37 0-3.307-.379-6.706-2.48-9.358-2.113-2.676-5.822-4.249-11.735-4.237zm8.681 13.595c0 3.066-.425 5.11-1.562 6.304-1.102 1.206-3.284 2.01-7.475 2.01h-8.463c-4.191 0-6.362-.793-7.464-2.01-1.137-1.194-1.562-3.226-1.55-6.304-.012-3.135.413-5.179 1.527-6.338 1.091-1.195 3.273-1.964 7.487-1.964h8.463c4.225 0 6.407.77 7.498 1.964 1.125 1.16 1.55 3.192 1.539 6.338zm39.971-13.596h-1.665v10.702h-21.691V4.314h-5.534v27.192h5.534V20.459h21.691v11.047h5.547V4.314h-3.882zm42.912 2.848c-2.412-1.998-5.868-2.848-10.358-2.848h-19.578v5.432h19.303c3.18-.012 5.339.46 6.614 1.274.964.632 1.538 1.413 1.848 2.642h-18.9c-3.548 0-6.224.55-8.119 2.066-1.906 1.528-2.71 3.916-2.687 6.833-.011 2.94.793 5.35 2.722 6.878 1.917 1.516 4.627 2.067 8.222 2.078h24.446V16.038c.012-3.8-1.102-6.878-3.513-8.876zm-1.941 11.93v6.994h-18.476c-2.87.011-4.409-.356-4.983-.827-.299-.23-.448-.448-.586-.873-.138-.424-.207-1.033-.207-1.837 0-.723.058-1.274.196-1.676.218-.586.447-.873 1.263-1.229.815-.333 2.25-.563 4.421-.551h18.372zm57.816-14.777h-1.665v21.898h-15.892V4.302h-5.535V26.2h-8.635c-3.491.011-5.293-.517-6.074-1.32-.816-.747-1.183-2.217-1.172-4.87V4.304h-5.534v15.881c0 3.238.471 6.109 2.446 8.222 1.986 2.101 5.201 3.089 10.059 3.089h35.895V4.303h-3.893v.012zm47.987 25.147l-1.171-.919-13.343-10.38 12.263-10.955 3.238-2.894h-8.152l-.494.39-21.944 19.533V4.314h-5.546v27.192h5.558l.459-.425 10.426-9.278 11.943 9.347.114.103.31.253h8.957l-2.618-2.044z"
    ></path>
  </svg>
)

export default Logo
