/** @jsx jsx */
import { Fragment, useState, useRef } from "react"
import PropTypes from "prop-types"
import VisuallyHidden from "@reach/visually-hidden"
import { jsx, Box } from "theme-ui"
import { alpha } from "@theme-ui/color"

import { CaretDown, CaretUp } from "components/svgs/caret"

const PartialCollpase = ({ height, bg, color, children }) => {
  const [isOpen, setOpen] = useState(false)
  const containerEl = useRef(null)

  const toggleOpen = () => {
    setOpen(!isOpen)
    containerEl.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  const modifiedStyles = isOpen
    ? {
        height: "auto",
        overflow: "auto",
      }
    : {
        height,
        overflow: "hidden",
        "&:after": {
          backgroundImage: t => `
          linear-gradient(
            to top,
            ${alpha(bg, 1.0)(t)},
            ${alpha(bg, 0.0)(t)}
          )
      `,
          display: "block",
          content: '""',
          height: "3rem",
          left: 0,
          position: "absolute",
          bottom: 0,
          width: "100%",
        },
      }

  return (
    <Fragment>
      <Box
        ref={containerEl}
        sx={{
          color,
          mb: 3,
          position: "relative",
          pt: "80px",
          mt: "-80px",
          ...modifiedStyles,
        }}
      >
        {children}
      </Box>
      <button
        sx={{
          background: "none",
          color,
          display: "block",
          border: "medium solid",
          borderColor: color,
          borderLeft: "none",
          borderRight: "none",
          py: 3,
          mb: 3,
          textAlign: "center",
          width: "100%",
        }}
        onClick={toggleOpen}
      >
        {isOpen ? <CaretUp /> : <CaretDown />}
        <VisuallyHidden>Toggle Content</VisuallyHidden>
      </button>
    </Fragment>
  )
}

PartialCollpase.defaultProps = {
  height: 500,
  color: "dark",
  bg: "background",
}

PartialCollpase.propTypes = {
  height: PropTypes.number,
  color: PropTypes.string,
  bg: PropTypes.string,
}

export default PartialCollpase
