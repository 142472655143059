import React from "react"

const Youtube = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill="currentColor"
      d="M29.912 8.141c-.341-1.36-1.348-2.433-2.627-2.796-2.318-.662-11.61-.662-11.61-.662s-9.294 0-11.612.662C2.785 5.708 1.778 6.78 1.436 8.14c-.621 2.467-.621 7.615-.621 7.615s0 5.147.62 7.614c.343 1.361 1.35 2.389 2.628 2.752 2.318.662 11.611.662 11.611.662s9.293 0 11.61-.662c1.28-.363 2.287-1.39 2.628-2.752.621-2.467.621-7.614.621-7.614s0-5.148-.62-7.615zM12.635 20.43v-9.347l7.767 4.674-7.767 4.673z"
    ></path>
  </svg>
)

export default Youtube
