/** @jsx jsx */
import PropTypes from "prop-types"
import VisuallyHidden from "@reach/visually-hidden"
import { Link } from "gatsby"
import { jsx } from "theme-ui"

import Container from "./container"
import Menu from "components/menu"

import Logo from "components/svgs/logo"

const Header = ({ siteTitle }) => (
  <header
    sx={{
      bg: "background",
      borderBottom: "0.625rem solid",
      borderBottomColor: "primary",
      width: "100%",
      position: "sticky",
      top: 0,
      variant: "layout.header",
      zIndex: 1080,
    }}
  >
    <Container>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <Logo />
          <VisuallyHidden>{siteTitle}</VisuallyHidden>
        </Link>
        <Menu />
      </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
