/** @jsx jsx */
import { jsx } from "theme-ui"

export default ({ children, overrideSx }, props) => (
  <div
    {...props}
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      mx: -3,
      ...overrideSx,
    }}
  >
    {children}
  </div>
)
