import React from "react"

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill="#1A1E25"
      d="M.893 21.9a1.5 1.5 0 010-2.122L19.278 1.393A1.5 1.5 0 1121.4 3.515L3.014 21.899a1.5 1.5 0 01-2.122 0z"
    ></path>
    <path
      fill="#1A1E25"
      d="M1.768 1.394a1.5 1.5 0 012.121 0l18.385 18.385a1.5 1.5 0 11-2.122 2.12L1.768 3.516a1.5 1.5 0 010-2.121z"
    ></path>
  </svg>
)

export default Cross
