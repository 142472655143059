/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

const Container = ({ children, wide, spaced }, props) => (
  <div
    {...props}
    sx={{
      maxWidth: wide ? [992, 1300, 1600] : [768, 992, 1300],
      mx: "auto",
      p: spaced ? 3 : 0,
      variant: "layout.container",
      width: "100%",
    }}
  >
    {children}
  </div>
)

Container.defaultProps = {
  wide: false,
  spaced: true,
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  spaced: PropTypes.bool,
}

export default Container
