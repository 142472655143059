import React from "react"

const Twitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill="currentColor"
      d="M27.02 9.981c.018.263.018.525.018.788 0 8.006-5.98 17.231-16.911 17.231A16.57 16.57 0 011 25.281c.478.056.938.075 1.435.075 2.779 0 5.337-.956 7.38-2.587-2.614-.056-4.804-1.8-5.558-4.2.368.056.736.094 1.122.094a6.18 6.18 0 001.565-.207c-2.724-.562-4.766-3-4.766-5.944v-.075c.79.45 1.711.732 2.686.77a6.083 6.083 0 01-2.65-5.045 6.1 6.1 0 01.81-3.056c2.926 3.675 7.324 6.075 12.255 6.338a6.96 6.96 0 01-.147-1.388C15.132 6.72 17.782 4 21.076 4a5.88 5.88 0 014.342 1.912A11.57 11.57 0 0029.19 4.45a6.02 6.02 0 01-2.613 3.337A11.74 11.74 0 0030 6.85a12.91 12.91 0 01-2.98 3.131z"
    ></path>
  </svg>
)

export default Twitter
