import React from "react"

export const CaretDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
    <path fill="currentColor" d="M9 15L.34 0h17.32L9 15z"></path>
  </svg>
)

export const CaretUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
    <path fill="currentColor" d="M9 0l8.66 15H.34L9 0z"></path>
  </svg>
)
