/** @jsx jsx */
import { useState, Fragment } from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import VisuallyHidden from "@reach/visually-hidden"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"

import Bars from "components/svgs/menu"
import Close from "components/svgs/cross"

import styles from "./styles"

const Menu = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <button onClick={() => setOpen(!isOpen)} sx={styles.button}>
        <VisuallyHidden>Toggle </VisuallyHidden>
        Menu
        {isOpen ? <Close /> : <Bars />}
      </button>
      <DialogOverlay
        sx={styles.overlay}
        isOpen={isOpen}
        onDismiss={() => setOpen(false)}
      >
        <DialogContent aria-label="Main Menu" sx={styles.content}>
          <div sx={styles.contentInner}>
            <Link to="/">1. Homepage</Link>
            <Link to="/numbers">2. By The Numbers</Link>
            <Link to="/climate">3. Climate &amp; Energy</Link>
            <Link to="/buildings">4. Buildings &amp; Grounds</Link>
            <Link to="/transportation">5. Transportation</Link>
            <Link to="/waste">6. Waste</Link>
            <Link to="/food">7. Food Systems</Link>
            <Link to="/participation">8. Participation</Link>
            <Link to="/academics">9. Academics &amp; Applied Research</Link>
          </div>
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  )
}

export default Menu
