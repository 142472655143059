import React from "react"

const Facebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill="currentColor"
      d="M12.557 31V17.582H8.062V12.25h4.495V8.049C12.557 3.484 15.346 1 19.418 1c1.951 0 3.627.146 4.113.21v4.77h-2.824c-2.215 0-2.643 1.055-2.643 2.596v3.674h4.998l-.685 5.332h-4.313V31"
    ></path>
  </svg>
)

export default Facebook
