import React from "react"

const Instagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill="currentColor"
      d="M16.47 8.178a7.542 7.542 0 00-7.553 7.555 7.542 7.542 0 007.553 7.555 7.542 7.542 0 007.553-7.555 7.542 7.542 0 00-7.553-7.555zm0 12.467a4.92 4.92 0 01-4.91-4.912 4.916 4.916 0 014.91-4.911 4.916 4.916 0 014.91 4.911 4.92 4.92 0 01-4.91 4.912zm9.624-12.776c0 .98-.789 1.763-1.762 1.763a1.762 1.762 0 111.762-1.762zm5.002 1.789c-.111-2.36-.65-4.452-2.38-6.174-1.722-1.723-3.812-2.262-6.172-2.38-2.432-.139-9.722-.139-12.155 0-2.353.111-4.443.65-6.172 2.373-1.73 1.723-2.262 3.814-2.38 6.174-.138 2.433-.138 9.725 0 12.158.112 2.36.65 4.451 2.38 6.174 1.729 1.723 3.812 2.262 6.172 2.38 2.433.138 9.723.138 12.155 0 2.36-.112 4.45-.65 6.173-2.38 1.722-1.723 2.261-3.814 2.38-6.174.138-2.433.138-9.718 0-12.151zm-3.142 14.761a4.972 4.972 0 01-2.8 2.801c-1.94.77-6.541.592-8.684.592-2.143 0-6.751.17-8.684-.592a4.972 4.972 0 01-2.8-2.8c-.77-1.94-.592-6.543-.592-8.687 0-2.143-.17-6.752.592-8.685a4.972 4.972 0 012.8-2.802c1.94-.769 6.541-.591 8.684-.591 2.143 0 6.751-.171 8.684.591a4.972 4.972 0 012.8 2.802c.77 1.94.592 6.542.592 8.685 0 2.144.177 6.753-.592 8.686z"
    ></path>
  </svg>
)

export default Instagram
