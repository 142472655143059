/** @jsx jsx */
import { jsx, Button, Styled } from "theme-ui"
import VisuallyHidden from "@reach/visually-hidden"

import Container from "./container"
import Col from "./col"
import Row from "./row"

import PartialCollapse from "components/partial-collapse"

import Facebook from "components/svgs/facebook"
import Twitter from "components/svgs/twitter"
import Instagram from "components/svgs/instagram"
import Youtube from "components/svgs/youtube"

const Footer = () => (
  <footer
    sx={{
      bg: "dark",
      color: "background",
      width: "100%",
      variant: "layout.footer",
      "& a": {
        color: "background",
        "&:hover": {
          textDecoration: "none",
        },
      },
    }}
  >
    <Container>
      <Row
        overrideSx={{
          alignItems: "flex-start",
          display: ["block", "flex"],
          textAlign: ["center", "left"],
        }}
      >
        <Col sx={{ mb: 3, maxWidth: ["none", "50%"] }}>
          <Styled.h3 as="h2" sx={{ color: "background" }}>
            About Mohawk
          </Styled.h3>
          <PartialCollapse height={50} color="background" bg="dark">
            <Styled.p sx={{ color: "background", fontSize: 0 }}>
              Mohawk College has been providing a quality, higher education
              experience to students in Hamilton, Ontario and the surrounding
              area since 1967. The college educates and serves more than 31,700
              full-time, part-time, apprenticeship and international students at
              three main campuses, and at learning hubs across Hamilton.
            </Styled.p>
            <Styled.p sx={{ color: "background", fontSize: 0 }}>
              Mohawk is among the leading colleges in Canada for applied
              research. It has been named one of Canada's greenest employers for
              six years in a row, and is also home to the country's largest net
              zero energy institutional building. More than 120,000 people have
              graduated from Mohawk since it was founded.
            </Styled.p>
          </PartialCollapse>
        </Col>
        <Col sx={{ mb: 3, maxWidth: ["none", "50%"] }}>
          <Styled.h3 as="h2" sx={{ color: "background" }}>
            Land Acknowledgment
          </Styled.h3>
          <PartialCollapse height={50} color="background" bg="dark">
            <Styled.p sx={{ color: "background", fontSize: 0 }}>
              Mohawk College derives its name from the Mohawk Nation, one of the
              Six Nations of the Haudenosaunee Confederacy. The Confederacy
              marks a union of peace and friendship, and Mohawk College honours
              that union by recognizing the Six Nations of the Grand River and
              the Mississaugas of the Credit First Nation.
            </Styled.p>
            <Styled.p sx={{ color: "background", fontSize: 0 }}>
              We acknowledge that the territories on which Mohawk College and
              its campuses are situated are governed by the Between the Lakes
              Treaty, the Dish with One Spoon Wampum Agreement, and the Upper
              Canada Treaty Agreement. These agreements are binding to this day
              and represent our mutual commitment to maintain healthy,
              reciprocal and respectful relationships with the many Indigenous
              nations and peoples of this area. We acknowledge this and iterate
              our dedication to inclusivity, to friendship and to valuing the
              ongoing contributions of Indigenous peoples and communities.
            </Styled.p>
          </PartialCollapse>
        </Col>
      </Row>
      <div
        sx={{
          display: ["block", "flex"],
          justifyContent: "space-between",
          textAlign: ["center", "left"],
        }}
      >
        <div>
          <Styled.p sx={{ fontSize: [0, 3], color: "background" }}>
            General Information - 905-575-1212
            <br />
            Admissions - 1-844-767-6871
          </Styled.p>
        </div>
        <div
          sx={{
            "&>a": {
              mx: 2,
              "&:hover": {
                color: "secondary",
              },
            },
          }}
        >
          <a href="https://www.facebook.com/mohawkcollege">
            <Facebook />
            <VisuallyHidden>Facebook</VisuallyHidden>
          </a>
          <a href="https://twitter.com/mohawkcollege">
            <Twitter />
            <VisuallyHidden>Twitter</VisuallyHidden>
          </a>
          <a href="https://www.instagram.com/mohawk.college/">
            <Instagram />
            <VisuallyHidden>Instagram</VisuallyHidden>
          </a>
          <a href="https://www.youtube.com/user/mohawkcollege">
            <Youtube />
            <VisuallyHidden>YouTube</VisuallyHidden>
          </a>
        </div>
      </div>
      <div
        sx={{
          display: ["block", "flex"],
          justifyContent: "space-between",
          textAlign: ["center", "left"],
        }}
      >
        <p>
          &copy; {new Date().getFullYear()} Mohawk College. Report by{" "}
          <a
            sx={{ color: "background", fontWeight: "bold" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://floating-point.com"
          >
            Floating-Point
          </a>
        </p>
        <p>
          <Button as="a" href="https://mohawkcollege.ca">
            Return to Mohawk College
          </Button>
        </p>
      </div>
    </Container>
  </footer>
)

export default Footer
