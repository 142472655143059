/** @jsx jsx */
import { jsx } from "theme-ui"

export default props => (
  <div
    {...props}
    sx={{
      px: 3,
      ...props.sx,
    }}
  >
    {props.children}
  </div>
)
